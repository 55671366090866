<template>
  <base-material-card icon="mdi-clipboard-text" title="Refund Request" class="px-5 py-3">
    <div class="project--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div class="form--group row" :class="{ 'has-error': validation.hasError('order') }">
          <label class="col-12 col-lg-3" for="orderField">
            <span>Order</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="order"
              id="orderField"
              v-model="order"
              :options="orderOptions"
              :searchable="true"
              :close-on-select="true"
              track-by="order_number"
              label="order_number"
              :internal-search="false"
              :preserve-search="true"
              :clearOnSelect="false"
              :loading="loading"
              @search-change="debounceGetOrders"
            />
            <span class="val-error">{{ validation.firstError('order') }}</span>
          </div>
        </div>
        <order-info :order="order"></order-info>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('selectedBank') }">
          <label class="col-12 col-lg-3" for="selectedBankField">
            <span>Nama Bank</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="selectedBank"
              id="selectedBankField"
              v-model="selectedBank"
              :options="banks"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('accountNumber') }">
          <label class="col-12 col-lg-3">
            <span>Nomor Rekening</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field type="text" outlined hide-details v-model="accountNumber" />
            <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('accountHolder') }">
          <label class="col-12 col-lg-3">
            <span>Nama Pemilik Rekening</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field type="text" outlined hide-details v-model="accountHolder" />
            <span class="val-error">{{ validation.firstError('accountHolder') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('refundAmount') }">
          <label class="col-12 col-lg-3">
            <span>Jumlah Refund</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field type="number" outlined hide-details v-model="refundAmount" />
            <span class="val-error">{{ validation.firstError('refundAmount') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('refundReason') }">
          <label class="col-12 col-lg-3">
            <span>Alasan Refund</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-textarea rows="5" type="text" outlined hide-details v-model="refundReason" />
            <span class="val-error">{{ validation.firstError('refundReason') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-end">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('refundInitiator.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const OrderInfo = () => import('@/components/refund-management/refund/order-info.vue');
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';

export default {
  name: 'refund-initiator-form',
  mixins: [HelperMixin, LodashMixin],
  components: {
    Multiselect,
    PageLoading,
    OrderInfo,
  },
  data() {
    return {
      debounceGetOrders: null,
    };
  },
  created() {
    this.debounceGetOrders = this.debounce(this.getOrders, 700);
  },
  computed: {
    ...mapState({
      basePath: (state) => state.refundInitiator.basePath,
      formType: (state) => state.refundInitiator.form.formType,
      banks: (state) => state.refundInitiator.form.banks,
      orderOptions: (state) => state.refundInitiator.form.orderOptions,
    }),
    loading: {
      get() {
        return this.$store.state.refundInitiator.form.loading;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_LOADING', value);
      },
    },
    order: {
      get() {
        return this.$store.state.refundInitiator.form.order;
      },
      async set(value) {
        this.$store.commit('refundInitiator/form/SET_ORDER', value);
        await this.$store.dispatch('refundInitiator/form/restoreInitialState');
        await this.$store.dispatch('refundInitiator/form/fetchBaseData', value ? value.uuid : null);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.refundInitiator.form.selectedBank;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_SELECTED_BANK', value);
      },
    },
    accountHolder: {
      get() {
        return this.$store.state.refundInitiator.form.accountHolder;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_ACCOUNT_HOLDER', value);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.refundInitiator.form.accountNumber;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_ACCOUNT_NUMBER', value);
      },
    },
    refundAmount: {
      get() {
        return this.$store.state.refundInitiator.form.refundAmount;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_REFUND_AMOUNT', value);
      },
    },
    refundReason: {
      get() {
        return this.$store.state.refundInitiator.form.refundReason;
      },
      set(value) {
        this.$store.commit('refundInitiator/form/SET_REFUND_REASON', value);
      },
    },
  },
  validators: {
    order(value) {
      return Validator.value(value).required(this.$i18n.t('errors.refund.order.required'));
    },
    selectedBank(value) {
      return Validator.value(value).required(this.$i18n.t('errors.refund.selectedBank.required'));
    },
    accountHolder(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.refund.accountHolder.required'))
        .maxLength(255, this.$i18n.t('errors.refund.accountHolder.max', { maxChar: 255 }));
    },
    accountNumber(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.refund.accountNumber.required'))
        .maxLength(255, this.$i18n.t('errors.refund.accountNumber.max', { maxChar: 255 }));
    },
    refundAmount(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.refund.refundAmount.required'))
        .greaterThanOrEqualTo(
          10000,
          this.$i18n.t('errors.refund.refundAmount.min', { min: 10000 }),
        );
    },
    refundReason(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.refund.refundReason.required'))
        .maxLength(1000, this.$i18n.t('errors.refund.refundReason.max', { maxChar: 1000 }));
    },
  },
  methods: {
    getOrders(query) {
      console.log(`GET ORDERS: `, query);
      this.$store.dispatch('refundInitiator/form/getOrders', query);
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('refundInitiator/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('refundInitiator.publish.successMsg'),
          'success',
        );
        await this.$router.replace(this.basePath);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
